import React, { Fragment } from 'react';

import PaySpinner from 'components/Core/Payment/PaySpinner/PaySpinner';
import Button from 'components/Shared/Button/Button';
import PaymentDisclaimer from 'components/Shared/PaymentDisclaimer/PaymentDisclaimer';
import { breakpoints } from 'styles/cp';
import { SelectedBank } from 'types/PayByBank';
import { Payment } from 'types/Payment';
import { TXN_MAP } from 'types/constants';

type PbbContinueButtonProps = {
  selectedBank: SelectedBank;
  dueDate: string;
  currency: string;
  payment: Pick<Payment, 'paymentMethodType' | 'paymentStatus' | 'amount'>;
  onContinuePbbButtonClick: () => void;
};

export const PbbContinueButton: React.FC<PbbContinueButtonProps> = ({
  selectedBank,
  payment,
  currency,
  dueDate,
  onContinuePbbButtonClick,
}) => {
  const isPaymentProcessing = payment.paymentStatus === TXN_MAP.STATUS.IN_PROGRESS;
  const isPaymentPending = payment.paymentStatus === TXN_MAP.STATUS.PBB_STATUSES.PENDING;

  return (
    <Fragment>
      {selectedBank?.bankName && !isPaymentPending && (
        <div className="pay-button">
          <Button
            disabled={isPaymentProcessing}
            buttonType="primary"
            className="pbb-btn"
            size="standard"
            width="100%"
            aria-label="Pay Button"
            onClick={onContinuePbbButtonClick}
          >
            {isPaymentProcessing ? (
              <PaySpinner paymentMethodType="PBB" isIntuitThemeButton={false} />
            ) : (
              <>Continue</>
            )}
          </Button>
        </div>
      )}
      <div className="pbb-disclaimer">
        <PaymentDisclaimer payment={payment} currency={currency} dueDate={dueDate} />
      </div>
      <style jsx>{`
        .pay-button {
          width: 100%;

          :global(.hidden-mobile) {
            @media screen and (max-width: ${breakpoints.md}) {
              display: none;
            }
          }
        }
        .pbb-disclaimer {
          padding-top: 15px;
        }
      `}</style>
    </Fragment>
  );
};
